.main-container {
  width: 100%;
  background-color: #fff;
}

.logo-container {
  background-color: #a2cafe;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-label {
  font-weight: 700;
  color: #002e63;
  font-size: 1.6rem;
  padding: 5px 0px 5px 1rem;
}

.select-container {
  padding: 5px 1rem 5px 0px;
}

.select-row-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
}

.select-img-container {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.select-label {
  font-size: 15px;
  color: #000;
}

.react-select__control:hover {
  cursor: none;
}

.react-select__dropdown-indicator {
  display: none;
}

.hero-container {
  background-color: #a2cafe;
  width: 100%;
  height: 90vh;
  display: flex;
}

.hero-img-container {
  width: 60%;
  height: 100%;
}

.hero-content-container {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 2.2rem;
}

.hero-heading-one {
  font-weight: 700;
  color: #002e63;
  font-size: 3.2rem;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

.hero-heading-two {
  font-weight: 700;
  color: #fff;
  font-size: 3rem;
  margin: 0px 0px 16px 0px;
  padding: 0px;
}

.hero-sub-heading-one {
  color: #fff;
  font-size: 20px;
  margin: 0px 0px 5px 0px;
  padding: 0px;
  letter-spacing: 0.9px;
}

.hero-sub-heading-two {
  color: #002e63;
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
}

.hero-sub-heading-three {
  color: #fff;
  font-size: 16px;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  letter-spacing: 0.5px;
}

.hero-sub-heading-three-bold {
  color: #002e63;
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0.5px;
}

.hero-contact-us-btn {
  width: fit-content;
  padding: 10px 2rem;
  color: #fff;
  background-color: #002e63;
  font-size: 16px;
  margin-top: 1rem;
  border-radius: 5px;
  border-color: #002e63;
  border-style: solid;
  border-width: 0.75px;
  cursor: pointer;
}

.expertise-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 1rem;
  background-color: #fff;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
}

h1 {
  padding: 0px;
  margin: 0px 0px 1rem 0px;
  font-size: 2.7rem;
}

.expertise-row-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.expertise-row-item-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 7px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 15px 20px;
  width: 30%;
  box-shadow: 0 10px 12px #e2e2e2;
}

.expertise-icon-container {
  width: 60px;
  height: 60px;
}

.expertise-icon-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h5 {
  margin: 0px;
  padding: 0px;
}

.expertise-item-heading {
  margin-bottom: 5px;
  margin-top: 15px;
  font-size: 17px;
  letter-spacing: 0.4px;
}

.expertise-item-content {
  font-size: 15px;
  color: #000;
  width: 100%;
  letter-spacing: 0.4px;
}

.automation-container {
  padding: 1.2rem 1rem;
  background-color: #e4f0ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.automation-sub-heading {
  font-size: 15px;
  color: #000;
  margin: 15px 0px;
  letter-spacing: 0.5px;
}

.automation-sub-heading-bold {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 15px 0px;
}

.automation-item-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.automation-item-row-item {
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  width: 220px;
  height: 70px;
  margin-top: 7px;
  margin-right: 7px;
  font-size: 15px;
  cursor: pointer;
}

.automation-item-row-item-sel {
  border-radius: 5px;
  background-color: #002e63;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  width: 220px;
  height: 70px;
  margin-top: 7px;
  margin-right: 7px;
  font-size: 15px;
  cursor: pointer;
}

.skills-container {
  padding: 1.2rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.skills-table-container {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-collapse: collapse;
  border-spacing: 1rem 0px;
}

th {
  padding: 15px 0px;
}

td {
  padding: 8px 0px;
}

.skills-table-heading-primary {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.skills-table-heading-secondary {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.skills-table-heading-row {
  border-top: 1px solid #454545;
  border-bottom: 1px solid #454545;
  background-color: #fff;
}

.skills-table-item-row-primary {
  border-bottom: 0.75px solid #dedede;
  background-color: #fff;
}

.skills-table-item-row-check {
  background-color: #e4f0ff;
}

.skills-table-item-row-check-parent-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.skills-table-item-row-check-img-container {
  background-color: transparent;
  width: 32px;
  height: 32px;
}

.pricing-container {
  padding: 1.2rem 1rem;
  background-color: #e4f0ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pricing-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.pricing-row-container-item {
  width: 28%;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 5px;
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pricing-label {
  font-size: 18px;
  letter-spacing: 0.7px;
  color: #000;
  text-align: center;
  width: 100%;
}

.pricing-starting-label {
  font-size: 13.1px;
  font-weight: 800;
  color: #002e63;
  align-self: start;
}

.pricing-price-parent-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  margin: 1.4rem 0px;
}
.pricing-price-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.price-currency-label {
  font-size: 13.5px;
  color: #002e63;
}

.price-price-label {
  font-size: 3rem;
  color: #000;
  font-weight: 800;
  margin-top: 0px;
  padding-top: 0px;
}

.price-price-hourly {
  font-size: 13.5px;
  align-self: flex-end;
  color: #002e63;
}

.pricing-feature-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
}

.pricing-feature-check-container {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.pricing-feature-label {
  font-size: 16px;
  color: #000;
  text-align: start;
}

.virtual-staff-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.virtual-staff-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 1rem;
  background-color: #fff;
}

.virtual-staff-row-item {
  border-radius: 12px;
  background-color: #fff;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  margin-right: 7px;
  margin-top: 10px;
  box-shadow: 0 10px 12px #e2e2e2;
  height: 200px;
}

.vitual-staff-row-item-label {
  font-size: 22px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.vitual-staff-row-item-text {
  font-size: 16px;
  color: #000;
  text-align: center;
}

.footer-parent-container {
  padding: 1.2rem 1rem;
  background-color: #e4f0ff;
  display: flex;
  flex-direction: row;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.footer-brand-label {
  font-size: 2rem;
  color: #002e63;
  font-weight: 700;
}

.footer-brand-sub-label {
  font-size: 16px;
  color: #000;
  margin-top: 1rem;
}

.automation-sub-cat-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.automation-sub-cat-container {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
  transition: transform 1s ease;
}

.automation-sub-cat-img-container {
  width: 120px;
  height: fit-content;
}

/* .automation-sub-cat-container:hover {
  transform: perspective(800px) rotateY(-180deg);
}

.automation-sub-cat-container.turned {
  transform: perspective(800px) rotateY(-180deg);
} */

.automation-sub-cat-label {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.pricing-request-quotation {
  font-size: 16px;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #002e63;
  border-color: #002e63;
  border-style: solid;
  padding: 10px 2rem;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.certification-img-parent-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.certification-img-container {
  width: 110px;
  height: 110px;
  margin-right: 10px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2.1rem;
  }

  .logo-label {
    font-weight: 700;
    color: #002e63;
    font-size: 1.2rem;
    padding: 5px 0px 5px 10px;
  }

  .select-container {
    padding: 5px 10px 5px 0px;
  }

  .expertise-row-item-container {
    width: 100%;
    margin-bottom: 10px;
  }

  .pricing-row-container-item {
    width: 98%;
  }

  .virtual-staff-row-item {
    width: 95%;
  }

  .hero-container {
    flex-direction: column;
  }

  .hero-img-container {
    width: 100%;
    height: 31%;
    order: -1;
  }

  .hero-content-container {
    width: 90%;
    padding: 0px 10px;
  }

  .hero-heading-one {
    font-size: 2.2rem;
    margin: 1rem 0px 0px 10px;
  }

  .hero-heading-two {
    font-size: 2.2rem;
    margin: 0px;
    padding: 0px;
  }

  .hero-sub-heading-one {
    font-size: 18px;
  }

  .hero-sub-heading-two {
    font-size: 18px;
  }

  .hero-sub-heading-three {
    font-size: 14px;
  }

  .hero-sub-heading-three-bold {
    font-size: 14px;
  }

  .footer-brand-label {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .certification-img-container {
    width: 90px;
    height: 90px;
  }

  .footer-parent-container {
    display: flex;
    flex-direction: column;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .certification-img-parent-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }
}
