@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Black.ttf");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}
